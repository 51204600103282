import type { LoaderArgs, V2_MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";

import styles from "./app.css";
import { Theme } from "./lib/enums";
import { requireUser } from "./server/authentication.server";
import { ThemeProvider, useTheme } from "./ui/Theme";

export function links() {
  return [{ rel: "stylesheet", href: styles }];
}

export async function loader({ request }: LoaderArgs) {
  // Logged out areas only have the dark theme
  if (!new URL(request.url).pathname.startsWith("/app")) {
    return json({ theme: Theme.Dark });
  }

  try {
    const authenticatedUser = await requireUser(request);
    return json({ theme: authenticatedUser.theme });
  } catch {
    return json({ theme: Theme.Dark });
  }
}

export const meta: V2_MetaFunction = () => [
  { charSet: "utf-8" },
  { title: "Diffs" },
  { name: "viewport", content: "width=device-width,initial-scale=1" },
];

function App() {
  const theme = useTheme();
  return (
    <html
      lang="en"
      className={`${
        theme === Theme.Dark ? "dark [color-scheme:dark]" : ""
      } min-h-full`}
    >
      <head>
        <Meta />
        <Links />
      </head>
      <body className="min-h-full bg-white dark:bg-midnight-900 dark:text-white">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

function Wrapper() {
  const { theme } = useLoaderData<typeof loader>();
  return (
    <ThemeProvider theme={theme as Theme}>
      <App />
    </ThemeProvider>
  );
}

export default withSentry(Wrapper);
